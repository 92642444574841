type DataLayerEvent = {
  event: string
  [key: string]: string
}

export default function () {
  function pushEvent(event: DataLayerEvent) {
    if (import.meta.server) {
      return
    }

    if (!window._mtm) {
      window._mtm = []
    }
    window._mtm.push(event)
  }

  return { pushEvent }
}

declare global {
  interface Window {
    _mtm?: any[]
  }
}
